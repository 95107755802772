import Head from 'next/head';

import type { MantlePage } from './_app';

const Home: MantlePage = () => {
  return (
    <Head>
      <meta httpEquiv="refresh" content="0; url=/dashboard" />
    </Head>
  );
};

Home.getLayout = (page) => page;

export default Home;
